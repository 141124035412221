import { Checkbox, Input, LoanInput, PhoneInput, Select } from '@components/rhf-mui5';
import styled from '@emotion/styled';
import { createSchemaFromOldSchema, step4Schema } from '@mikra/validation';
import { Alert, Box, Button, Hidden, Typography } from '@mui/material';
import { graphql, navigate } from 'gatsby';
import { darken } from 'polished';
import React, { useState } from 'react';
import { FaCoins, FaHome, FaIdCard, FaQuestionCircle } from 'react-icons/fa';
import { IoIosCheckmark } from 'react-icons/io';
import { useEffectOnce } from 'react-use';
import LocalCommunitySelect from '../components/LocalCommunitySelect';
import Seo from '../components/Seo';
import Upload from '../components/Upload';
import Wizard, { FormWrapper, WizardStep } from '../components/Wizard';
import useFirebaseAuth from '../hooks/useFirebaseAuth';
import useFormContext from '../hooks/useFormContext';
import Layout from '../layout';
import axios from '../utils/axios';

const returnInputForGivenType = ({ type, label, name, options }, registry, state, index) => {
  switch (type) {
    case 'INPUT_NUMBER':
      return <Input label={label} name={name} type="number" key={index} />;
    case 'LOAN_INPUT':
      return <LoanInput amountProps={{ max: 10000, min: 500, step: 100 }} repaymentProps={{ max: 60 }} />;
    case 'INPUT':
      return <Input label={label} name={name} key={index} />;
    case 'PHONE_INPUT':
      return <PhoneInput label={label} name={name} key={index} />;
    case 'CHECKBOX':
      return <Checkbox label={label} name={name} key={index} />;
    case 'SELECT':
      return <Select name={name} label={label} options={registry?.[options] || []} key={index} />;
    case 'LOCAL_COMMUNITY_SELECT':
      return <LocalCommunitySelect defaultValue={state.municipality} key={index} />;
    default:
      return <div key={index}></div>;
  }
};

const IndexPage = ({ data, location }) => {
  const [fillClientData, setFillClientData] = useState(false);
  const { state, dispatch } = useFormContext();
  const { user, signInWithCustomToken, signOut } = useFirebaseAuth();
  const [ssn, setSSN] = useState('');

  const handleSubmit = async () => {
    await axios.post(`/wizard/confirm`);
    if (!user?.role) await signOut();
    dispatch({ reset: true });
    navigate(`/zahtjev-primljen`, { replace: true });
  };

  const { loanApplicationId } = state;
  const { strapiKorak3, strapiKorak4, strapiAplikacijaZaFinansiranje, allForm, registry } = data;
  const promocode = location?.state?.promocode;
  const promoCode = promocode && promocode > 0 ? promocode : null;

  const returnFieldsForStep = (step) =>
    allForm.edges
      .filter(({ node }) => node.stepWeb === step)
      .reduce((arr, { node }) => {
        return [...arr, { ...node }];
      }, []);

  const stepOneInputs = returnFieldsForStep(1);
  const stepTwoInputs = returnFieldsForStep(2);
  const stepThreeInputs = returnFieldsForStep(3);

  const stepOneSchema = createSchemaFromOldSchema(stepOneInputs);
  const stepTwoSchema = createSchemaFromOldSchema(stepTwoInputs);
  const stepThreeSchema = createSchemaFromOldSchema(stepThreeInputs);

  useEffectOnce(() => {
    dispatch({ step1Timestamp: new Date().toISOString() });
  });


  return (
    <Layout offset={1} formBg>
      <Box maxWidth={1200} mx="auto" width="100%" mt={[0, 5]}>
        <Box
          display="flex"
          justifyContent={{ xs: 'center', xl: 'space-between' }}
          gap={5}
          px={{ sm: 0, md: 2 }}
          pb={10}
          alignItems="center"
        >
          <Hidden xlDown implementation="css">
            <Box mt="10vh" maxWidth={460} display="flex" flexDirection="column" gap={4}>
              <Typography variant="h4">{strapiAplikacijaZaFinansiranje.naslov}</Typography>
              <Typography variant="subtitle2">{strapiAplikacijaZaFinansiranje.opis}</Typography>
              <Box display="flex" borderBottom="1px solid lightgrey" pb={2} mt={2} gap={2} alignItems="center">
                <Box height={33} width={33} bgcolor="#392767" textAlign="center" pt={0.4} borderRadius={100}>
                  <IoIosCheckmark size={28} color="white" />
                </Box>
                <Typography variant="body2" color="textPrimary" fontWeight={600}>
                  {strapiAplikacijaZaFinansiranje.stavka1}
                </Typography>
              </Box>
              <Box display="flex" borderBottom="1px solid lightgrey" pb={2} gap={2} alignItems="center">
                <Box height={33} width={33} bgcolor="#392767" textAlign="center" pt={0.4} borderRadius={100}>
                  <IoIosCheckmark size={28} color="white" />
                </Box>
                <Typography variant="body2" color="textPrimary" fontWeight={600}>
                  {strapiAplikacijaZaFinansiranje.stavka2}
                </Typography>
              </Box>
              <Box display="flex" borderBottom="1px solid lightgrey" gap={2} pb={2} alignItems="center">
                <Box height={33} width={33} bgcolor="#392767" textAlign="center" pt={0.4} borderRadius={100}>
                  <IoIosCheckmark size={28} color="white" />
                </Box>
                <Typography variant="body2" color="textPrimary" fontWeight={600}>
                  {strapiAplikacijaZaFinansiranje.stavka3}
                </Typography>
              </Box>
              <Box display="flex" borderBottom="1px solid lightgrey" gap={2} pb={2} alignItems="center">
                <Box height={33} width={33} bgcolor="#392767" textAlign="center" pt={0.4} borderRadius={100}>
                  <IoIosCheckmark size={28} color="white" />
                </Box>
                <Typography variant="body2" color="textPrimary" fontWeight={600}>
                  {strapiAplikacijaZaFinansiranje.stavka4}
                </Typography>
              </Box>
            </Box>
          </Hidden>
          <FormWrapper>
            {promoCode && (
              <Box width="106px" height="106px" overflow="hidden" position="absolute" top="-8px" left="-8px">
                <Ribbon>Akcija</Ribbon>
              </Box>
            )}
            <Seo title="Zahtjev za kredit" />
            <Wizard onSubmit={handleSubmit} fillClientData={fillClientData}>
              <WizardStep
                onSubmit={async (values) => {
                  if (user) {
                    axios.patch(`/wizard/step1`, values);
                  } else dispatch({ step2Timestamp: new Date().toISOString() });
                }}
                schema={stepOneSchema}
              >
                {stepOneInputs.map((node, index) => returnInputForGivenType(node, registry.adminData, state, index))}
              </WizardStep>
              <WizardStep
                onSubmit={async (values) => {
                  if (user) axios.patch(`/wizard/step2`, values);
                  else {
                    const { data } = await axios.post(`/wizard/start`, {
                      ...state,
                      ...values,
                      promoCode,
                    });
                    const { token, loanApplicationId } = data;
                    dispatch({ loanApplicationId, promoCode });
                    typeof window !== 'undefined' &&
                      window.localStorage.setItem('loanApplicationId', loanApplicationId);
                    if (!user) await signInWithCustomToken(token);
                  }
                }}
                schema={stepTwoSchema}
              >
                {user?.role === 'komitent' ? (
                  <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
                    Vaši osnovni podaci mogu automatski biti popunjeni na osnovu prethodnih zahtjeva.
                    <Button sx={{ p: 0, fontSize: 14, mt: -0.1, ml: 1 }} onClick={() => setFillClientData((v) => !v)}>
                      Preuzmi podatke
                    </Button>
                  </Alert>
                ) : (
                  <div></div>
                )}
                {stepTwoInputs.map((node, index) => returnInputForGivenType(node, registry.adminData, state, index))}
              </WizardStep>
              <WizardStep
                onSubmit={async (values) => {
                  if (user) axios.patch(`/wizard/step3`, values);
                  setSSN(values?.jmbg);
                }}
                schema={stepThreeSchema}
              >
                {stepThreeInputs.map((node, index) => returnInputForGivenType(node, registry.adminData, state, index))}
                <Box textAlign="end" mt={1}>
                  <Typography variant="caption">
                    <FaQuestionCircle /> Ako nemate postojeći kredit unesite 0.
                  </Typography>
                </Box>
              </WizardStep>
              <WizardStep schema={step4Schema}>
                <Upload
                  name="lkFiles"
                  title={strapiKorak4.lk.naslov}
                  description={strapiKorak4.lk.podnaslov}
                  icon={<FaIdCard />}
                  meta={{
                    doctype: 687,
                    category: 'lkFiles',
                    loanApplicationId:
                      !!loanApplicationId || typeof window === 'undefined'
                        ? loanApplicationId
                        : window.localStorage.getItem('loanApplicationId'),
                    ssn: ssn
                  }}
                />
                <Upload
                  name="residenceProofFiles"
                  title={strapiKorak4.boravak.naslov}
                  description={strapiKorak4.boravak.podnaslov}
                  icon={<FaHome />}
                  meta={{
                    doctype: 692,
                    category: 'residenceProofFiles',
                    loanApplicationId:
                      !!loanApplicationId || typeof window === 'undefined'
                        ? loanApplicationId
                        : window.localStorage.getItem('loanApplicationId'),
                    ssn: ssn
                  }}
                />
                <Upload
                  name="incomeProofFiles"
                  doctype="695"
                  title={strapiKorak4.primanja.naslov}
                  description={strapiKorak4.primanja.podnaslov}
                  icon={<FaCoins />}
                  meta={{
                    doctype: 695,
                    category: 'incomeProofFiles',
                    loanApplicationId:
                      !!loanApplicationId || typeof window === 'undefined'
                        ? loanApplicationId
                        : window.localStorage.getItem('loanApplicationId'),
                    ssn: ssn    
                  }}
                />
              </WizardStep>
            </Wizard>
          </FormWrapper>
        </Box>
      </Box>
    </Layout>
  );
};

const Ribbon = styled.div`
  font: bold 12px ${({ theme }) => theme.typography.fontFamily};
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  position: relative;
  padding: 7px 0;
  left: -33px;
  top: 26px;
  width: 150px;
  background-color: ${({ bgcolor, theme }) => bgcolor || theme.palette.secondary.main};
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.5px;

  &:before,
  &:after {
    content: '';
    border-top: 4px solid ${({ bgcolor, theme }) => darken(0.4, bgcolor || theme.palette.secondary.main)};
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    position: absolute;
    bottom: -4px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
`;

export const query = graphql`
  query DataQuery {
    registry {
      adminData {
        activationTypes {
          value
          text
        }
        cities {
          value
          text
        }
        employmentStatus {
          value
          text
        }
        genders {
          value
          text
        }
        loanProducts {
          value
          text
        }
        loanPurposeActivities {
          value
          text
        }
        loanPurposes {
          value
          text
        }
        loanType {
          value
          text
        }
        martialStatus {
          value
          text
        }
        offices {
          value
          text
        }
        payoutType {
          value
          text
        }
      }
      children {
        id
      }
    }
    allForm(
      filter: { active: { eq: "D" }, userPrivilege: { eq: 1 }, type: { ne: "HEADING" } }
      sort: { fields: [stepWeb, stepOrderWeb] }
    ) {
      edges {
        node {
          formId
          active
          componentType
          type
          editable
          formValidationId
          label
          name
          options
          params
          stepWeb
          stepOrderWeb
          validations
          validationType
          width
        }
      }
    }
    strapiAplikacijaZaFinansiranje {
      naslov
      podnaslov
      opis
      stavka1
      stavka2
      stavka3
      stavka4
    }
    strapiKorak3 {
      postojeceZaduzenje {
        zaduzenje
        zaduzenjeDomacinstvo
        zaduzenjePodnosioc
      }
    }
    strapiKorak4 {
      lk {
        naslov
        podnaslov
        dugmeTekst
      }
      boravak {
        naslov
        podnaslov
        dugmeTekst
      }
      primanja {
        naslov
        podnaslov
        dugmeTekst
      }
    }
  }
`;

export default IndexPage;
